<template>
  <div class="databaseLink">
    <div class="buttonBox">
      <el-button size="small" type="primary" @click="adddata">新增</el-button>
      <el-button size="small" type="danger" @click="selectDelete">删除</el-button>
    </div>
    <div class="tableBox">
      <el-table ref="tableData" :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" height="100%">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
        <el-table-column prop="connName" label="链接名称" align="center"></el-table-column>
        <el-table-column prop="dbType" label="数据库连接类型" align="center"></el-table-column>
        <el-table-column prop="host" label="主机地址" align="center"></el-table-column>
        <el-table-column prop="db" label="数据库名称" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="setdata(scope.row)"></el-button>
            <el-popconfirm title="确定删除吗？" @confirm="deleteData(scope.row)">
              <el-button slot="reference" style="color: red" type="text" icon="el-icon-delete"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="pageNum"
        :page-sizes="[20, 2, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog :title="titlespan" :visible.sync="dialog" width="40%">
      <el-form :model="form">
        <el-form-item label="链接名称" :label-width="formLabelWidth">
          <el-input v-model="form.connName" placeholder="链接名称"></el-input>
        </el-form-item>
        <el-form-item label="链接类型" :label-width="formLabelWidth">
          <el-select clearable v-model="form.dbType" placeholder="请选择链接类型">
            <el-option label="sqlserver" value="sqlserver"></el-option>
            <el-option label="mysql" value="mysql"></el-option>
            <el-option label="oracle" value="oracle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主机地址" :label-width="formLabelWidth">
          <el-input v-model="form.host" placeholder="主机地址"></el-input>
        </el-form-item>
        <el-form-item label="端口" :label-width="formLabelWidth">
          <el-input v-model="form.port" placeholder="端口"></el-input>
        </el-form-item>
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="form.username" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model="form.pwd" type="password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item label="数据库" :label-width="formLabelWidth">
          <el-input v-model="form.db" placeholder="数据库"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="csljclick">测试链接</el-button>
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="closeDrawer" :loading="loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  dbConnectSave,
  dbConnectSelect,
  dbConnectDelete,
  dbTestConnect,
} from "@/api/dataBaseLink";
export default {
  name: "databaseLink",
  data() {
    return {
      tableData: [],
      dialog: false,
      titlespan: "新增",
      formLabelWidth: "80px",
      form: {},
      loading: false,
      pageNum: 1,
      pageSize: 20,
      total: 0,
      ids: [],
    };
  },
  mounted() {
    this.init();
  },
	methods: {
		// 初始化方法
    init() {
      let params = {pageNum : this.pageNum, pageSize:this.pageSize }
      dbConnectSelect(params).then((res) => {
        this.tableData = res.result;
        this.pageNum = res.pageNum;
        this.total = res.totalRecord;
        setTimeout(() => {
          this.$refs.tableData?.doLayout();
        });
      });
		},

		// 分页器方法
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.init({ pageNum: this.pageNum, pageSize: this.pageSize });
		},
		// 分页器方法
    handleCurrentChange(val) {
      this.pageNum = val;
      this.init({ pageNum: this.pageNum, pageSize: this.pageSize });
    },

		// 弹窗测试链接按钮
    csljclick() {
      let dbConnConfig = this.form;
      dbTestConnect(dbConnConfig).then((res) => {
				if (!(res.status) || res.errMsg) {
					this.$message({ message: res.errMsg, type: "error" });
				} else this.$message({ message: "链接成功", type: "success" });
      });
		},

		// 新增/编辑弹窗确认按钮
    closeDrawer() {
      let dbConnConfig = this.form;
      dbConnectSave(dbConnConfig).then((res) => {
        this.form = {};
        this.dialog = false;
        this.$message({ message: "保存成功", type: "success" });
        this.init({ pageNum: 1, pageSize: 20 });
      });
		},

		// 新增按钮
    adddata() {
      this.form = {}
      this.dialog = true;
      this.titlespan = "新增";
		},

		// 行编辑按钮
    setdata(row) {
      this.dialog = true;
      this.titlespan = "编辑";
      this.form = row;
		},

		// 行删除按钮
    deleteData(row) {
      let ids = [];
      ids.push(row.id);
      dbConnectDelete(ids).then((res) => {
        this.$message({ message: "删除成功", type: "success" });
        this.init({ pageNum: this.pageNum, pageSize: this.pageSize });
      });
		},
		
    // 复选框多选数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
		},
		
    // 多选删除按钮
    selectDelete() {
      if (this.ids.length >= 1) {
        this.$confirm(" 是否继续删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          dbConnectDelete(this.ids).then((res) => {
            this.$message({ message: "删除成功", type: "success" });
            this.init({ pageNum: this.pageNum, pageSize: this.pageSize });
          });
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.databaseLink {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  position: relative;
}
.el-pagination {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.buttonBox {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.tableBox {
  height: ceil(90% - 10px);
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}
.demo-drawer__content {
  height: 100%;
  form {
    height: 90%;
  }
  .demo-drawer__footer {
    height: 10%;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    button {
      flex: 1;
      height: 50px;
    }
  }
}
</style>
