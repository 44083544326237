import request from "@/axios/index";
// 查询流程定义

// 数据库连接新增保存
export function dbConnectSave(data) {
  return request({
    url: '/db_conn_config',
    method: "post",
    data
  })
}
// 测试链接接口
export function dbTestConnect(data) {
  return request({
    url: '/db_conn_config/test_conn',
    method: "post",
    data
  })
}

/* 数据库连接分页查询 */
export function dbConnectSelect(params) {
  return request({
    url: '/db_conn_config/page',
    method: "get",
    params
  })
}


export function dbConnectDelete(ids) {
  return request({
    url: '/db_conn_config',
    method: "delete",
    data: ids
  })
}